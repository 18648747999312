const en = {
  app_words: {
    sweet: "Sweet",
    romantic: "Romantic",
    dailywords: "Daily Words",
    horror: "Horror",
    question: "Question",
    fantasy: "Fantasy",
    next_random: "Next random",
    next_sweet: "Next sweetness",
    next_romantic: "Next romantic",
    next_dailywords: "Next daily words",
    next_horror: "Next horror",
    next_question: "Next question",
    next_fantasy: "Next fantasy",
    gf: "girlfriend",
    bf: "boyfriend",
    ask_bf: "Ask {bf} to say this ❤️",
    ask_gf: "Ask {gf} to say this ❤️",
    no: "No!",
    credit:
      "Embarassing lines that you want\nyour boyfriend/girlfriend to tell you",
    long_credit:
      "Welcome to the website 'Embarassing lines that you want your boyfriend/girlfriend to tell you'!",
    url: "https://nomikaiserifu.com/en",
    share_title:
      "A website about 'Embarassing lines that you want your boyfriend/girlfriend to tell you'? It's so fun!",
    tap_here:
      "To find an embarassing line that you want to be told by your boyfriend, tap this ↓",
    back: "Back",
    share: "Share",
    new_random: "Embarassing line",
    can_also_choose_topic: "or choose a topic ↓",
    recommended_for:
      "Recommended for party games! Tell someone to say the line when they lose!",
    about: "About the creator",
    thanks: "Thanks so much for coming",
    send_mail_instructions: "Please send me email about feedback and ideas ↓",
  },
  color: {
    sweet: "#ffc1f9",
    romantic: "#C2185B",
    dailywords: "#DEC6FC",
    horror: "#C9C9C9",
    question: "#66CADF",
    fantasy: "#8C8AFF",
  },
  gradient: {
    sweet: `radial-gradient(
      50% 960.25% at 50% 50%,
      #fa00ff 0%,
      rgba(157, 190, 255, 0.708333) 85.42%,
      rgba(189, 211, 255, 0) 100%
    )`,
    romantic: `radial-gradient(50% 960.25% at 50% 50%, #EA3C31 0%, rgba(157, 190, 255, 0.708333) 85.42%, rgba(189, 211, 255, 0) 100%)`,
    horror: `radial-gradient(50% 960.25% at 50% 50%, #6C6F75 38.02%, rgba(45, 95, 191, 0) 100%)`,
    dailywords: `radial-gradient(50% 960.25% at 50% 50%, #C3D3F0 0%, rgba(100, 152, 251, 0) 100%)`,
    question: `radial-gradient(50% 960.25% at 50% 50%, #FDDDD3 47.92%, rgba(237, 240, 246, 0) 100%)`,
    fantasy: `radial-gradient(50% 960.25% at 50% 50%, #BCEEAA 47.92%, rgba(237, 240, 246, 0) 100%) `,
  },
  sweet_el: {
    sweet_el_1:
      "(Your boyfriend/girlfriend name), I love you! Let’s be together forever!",
    sweet_el_2: "I will protect you forever! It’s a promise!",
    sweet_el_3: "If a whale ate both you and my best friend, I’d save you!",
    sweet_el_4: "If you told me to jump down a bridge, I’d kiss you!",
    sweet_el_5: "I like your last name, can I have it?",
    sweet_el_6:
      "If one day we get married, I would like to have {random} daughters and {random} sons having (Your boyfriend/girlfriend last name)",
    sweet_el_7:
      "In this world, there is no one else who is more beautiful than you.",
  },
  romantic_el: {
    romantic_el_1: "Hey! I’m embarrassed! Cut that out!",
    romantic_el_2: "If you are milk, I am tea. Let’s make milk tea together!",
    romantic_el_3:
      "You also breathe? Wow, let’s touch your mouth with my mouth to breathe together.",
    romantic_el_4:
      "If you are a flower, I think it’s a beautiful one with two mouths.",
    romantic_el_5: "Kiss me now!",
    romantic_el_6:
      "Don’t say anything, use your hands to prove that you love me.",
    romantic_el_7: "Let’s eat some Pocky together! If you don’t, I’ll eat you",
    romantic_el_8:
      "Let’s eat some Pocky together! If you don’t, I won’t be able to try your delicious taste.",
    romantic_el_9:
      "Let’s eat some Pocky together so that we can fit together perfectly!",
    romantic_el_10:
      "Let's hold hands and chew Pocky together. If you are not by my side, this sweet taste will only taste half as good.",
  },
  horror_el: {
    horror_el_1:
      "If a zombie apocalypse happened, and a zombie teared you apart, I’d become a doctor and put you together.",
    horror_el_2:
      "If you get killed and I could make you live by losing my life, I’ll do so.",
    horror_el_3:
      "If a zombie apocalypse happened, and a zombie tore you apart, I’d put all of your parts in a refrigerator to preserve them forever.",
    horror_el_4:
      "If a zombie apocalypse happened, and a zombie tore you apart, I’d learn to cook.",
    horror_el_5:
      "I’ll follow you forever, because I’m a police officer and you are a serial killer.",
    horror_el_6:
      "When I become a ghost, I will follow you for ten thousand years.",
    horror_el_7:
      "If we were locked in a room without anything to eat, I’ll eat you.",
    horror_el_8:
      "When I want you, I feel like I’m starving. Even if I’m locked up without anything to eat, I’m content as long as you are by my side.",
    horror_el_9:
      "If a zombie apocalypse happened, and a zombie tore you apart, you would live with me forever inside me.",
    horror_el_10:
      "If a zombie apocalypse happened, and a zombie tore you apart, I wouldn't let you be eaten by the zombie. Only I would be eaten.",
  },
  dailywords_el: {
    dailywords_el_1:
      "This world is so beautiful, but it’s so large, so I can’t go alone. Let’s go and watch it together while you are carrying me like a princess!",
    dailywords_el_2:
      "I think you don’t know, but actually, I secretly watch every of your small gestures.",
    dailywords_el_3:
      "I’m jealous with your plushie, because it can be with you all day long.",
  },

  question_el: {
    question_el_1:
      "If a whale ate both you and my best friend, who would you save?",
    question_el_2: "I told you to jump down a bridge, would you do that?",
    question_el_3: "Do you like roughness or gentleness?",
    question_el_4:
      "If you were walking on the street and a pretty girl/a handsome guy suddenly hugged you, how would you react?",
    question_el_5:
      "If we were walking on the street together, and it suddenly rained, what would you do?",
    question_el_6:
      "If I got a disease that makes it impossible for you to touch me, would you still love me?",
  },
  fantasy_el: {
    fantasy_el_1: "I would still love you if you became a Demon Lord.",
    fantasy_el_2:
      "If a demon took you away, I’d definitely become a hero and save you.",
    fantasy_el_3:
      "It’s easy to become a hero, but it’s ten times harder to satisfy your needs.",
  },
};
export default en;
