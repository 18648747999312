import React, { useState, useEffect } from "react";
import "./styles.css";
import ButtonWithHeart from "../ButtonWithHeart";
import { Button } from "antd";
import { useLocation } from "react-router-dom";
import { t } from "../tools.js";
import { TwitterShareButton } from "react-share";

function EL({ navigate }) {
  const location = useLocation();
  const lang = location.state.lang;
  const [cat, setCat] = useState(location.state.cat);
  const [el, setEl] = useState(t(cat, "el", lang));
  const [turn, setTurn] = useState("ask_bf");
  const [firstClickTime, setFirstClickTime] = useState(new Date());

  const handleClick = (catButton) => {
    const last_el = el;
    const cat_list = [
      "sweet",
      "romantic",
      "dailywords",
      "horror",
      "question",
      "fantasy",
    ];

    const secondClickTime = new Date();
    const timeDifference = secondClickTime - firstClickTime;

    let event_type;
    if (catButton) {
      setEl(t(cat, "el", lang));
      event_type = `${cat}`;
    } else {
      setCat(cat_list[Math.floor(Math.random() * cat_list.length)]);
      setEl(t(cat, "el", lang));
      event_type = `random`;
    }
    turnTheTurn();

    const logData = {
      event_category: "Button clicks",
      event_type,
      last_el,
      time_spent_on_last_el: timeDifference / 1000,
    };

    window.gtag("event", "el_click", logData);
    setFirstClickTime(secondClickTime);
  };

  useEffect(() => {
    setEl(t(cat, "el", lang));
  }, [cat]);

  const turnTheTurn = () => {
    if (turn == "ask_bf") setTurn("ask_gf");
    else setTurn("ask_bf");
  };

  const onClickHome = () => {
    navigate("/");
  };

  const onClickShare = () => {
    const logData = {
      event_category: "Button clicks",
      event_type: "Share",
      last_el: el,
    };

    window.gtag("event", "share_click", logData);
  };

  return (
    <div
      id="root-container"
      style={{
        height: window.innerHeight,
        width: window.innerWidth,
        background: t(cat, "color", lang),
      }}
    >
      <div id="container">
        <div id="top">
          <Button
            style={{
              width: 80,
              background: "#FFF",
              fontSize: 16,
              color: "#848484",
            }}
            onClick={onClickHome}
          >
            {t("back", "app_words", lang)}
          </Button>
          <Button
            style={{ width: 150, background: t(cat, "gradient", lang) }}
            className="badge"
          >
            {t(cat, "app_words", lang)}
          </Button>
          <TwitterShareButton
            url={t("url", "app_words", lang)}
            title={t("share_title", "app_words", lang)}
          >
            <Button
              style={{
                width: 80,
                background: "#FFF",
                fontSize: 16,
                color: "#848484",
              }}
              onClick={onClickShare}
            >
              {t("share", "app_words", lang)}
            </Button>
          </TwitterShareButton>
        </div>
        <div id="ask-container">
          <div id="ask-line"> {t(turn, "app_words", lang)}</div>
          <div id="no-button-container">
            <Button className="no-button" onClick={turnTheTurn}>
              {t("no", "app_words", lang)}
            </Button>
          </div>
        </div>
        <div id="el-container">
          <div id="el">{el}</div>
        </div>
        <div id="bot">
          <div id="button-container">
            <ButtonWithHeart
              handleClick={handleClick}
              buttonText={`${t("next_random", "app_words", lang)}`}
            />
            <ButtonWithHeart
              catButton
              handleClick={handleClick}
              buttonText={`${t("next_" + cat, "app_words", lang)}`}
            />
          </div>
          <div id="credit-container">
            <div id="credit">{t("credit", "app_words", lang)}</div>
            <a href="https://nomikaiserifu.com">nomikaiserifu.com</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EL;
