const jp = {
  app_words: {
    sweet: "甘い",
    romantic: "ロマンチック",
    dailywords: "日常",
    horror: "ホラー",
    question: "質問",
    fantasy: "ファンタジー",
    next_random: "次のランダム",
    next_sweet: "次の甘さ",
    next_romantic: "次のロマンチック",
    next_dailywords: "次の日常",
    next_horror: "次のホラー",
    next_question: "次の質問",
    next_fantasy: "次のファンタジー",
    gf: "彼女",
    bf: "彼氏",
    ask_bf: "{bf}に言うように頼んで ❤️",
    ask_gf: "{gf}に言うように頼んで ❤️",
    no: "いやだ!",
    credit: "「飲み会で彼氏に言わせたい\n恥ずかしい台詞」",
    long_credit:
      "「飲み会で彼氏に言わせたい恥ずかしい台詞」のサイトへようこそ！",
    url: "https://nomikaiserifu.com",
    share_title:
      "「飲み会で彼氏に言わせたい恥ずかしい台詞」のウェブサイト? ｗｗ　面白いね！一緒に遊んで！",
    tap_here: "彼氏に言わせたいセリフを探すには、このボタンをタップして ↓",
    back: "戻る",
    share: "共有",
    new_random: "恥ずかしい台詞",
    can_also_choose_topic: "トピックでも選べる ↓",
    recommended_for: "飲み会、罰ゲーム、王様ゲームにはがおすすめサイトだよ！",
    about: "サイトについて",
    thanks: "ありがとうございました",
    send_mail_instructions:
      "フィードバックやアイデアについては、Discord またはメールでご連絡ください。↓",
  },
  color: {
    sweet: "#ffc1f9",
    romantic: "#C2185B",
    dailywords: "#DEC6FC",
    horror: "#C9C9C9",
    question: "#66CADF",
    fantasy: "#8C8AFF",
  },
  gradient: {
    sweet: `radial-gradient(
      50% 960.25% at 50% 50%,
      #fa00ff 0%,
      rgba(157, 190, 255, 0.708333) 85.42%,
      rgba(189, 211, 255, 0) 100%
    )`,
    romantic: `radial-gradient(50% 960.25% at 50% 50%, #EA3C31 0%, rgba(157, 190, 255, 0.708333) 85.42%, rgba(189, 211, 255, 0) 100%)`,
    horror: `radial-gradient(50% 960.25% at 50% 50%, #6C6F75 38.02%, rgba(45, 95, 191, 0) 100%)`,
    dailywords: `radial-gradient(50% 960.25% at 50% 50%, #C3D3F0 0%, rgba(100, 152, 251, 0) 100%)`,
    question: `radial-gradient(50% 960.25% at 50% 50%, #FDDDD3 47.92%, rgba(237, 240, 246, 0) 100%)`,
    fantasy: `radial-gradient(50% 960.25% at 50% 50%, #BCEEAA 47.92%, rgba(237, 240, 246, 0) 100%) `,
  },
  sweet_el: {
    sweet_el_1:
      "私は (彼氏・彼女の名前)を愛してる！ これからもずっと一緒だよ！！",
    sweet_el_2: "君を愛してるから、ずっと守ってあげる！それが私の約束！",
    sweet_el_3: "クジラが君と私の親友の両方を食べたら、私は君を救い出す！",
    sweet_el_4: "いつか気が狂って橋から飛び降りろと言われたらキスしてやる！",
    sweet_el_5: "私は君の名字が好きだ。名字をくれない？",
    sweet_el_6:
      "もし私たちが結婚する日が来たら、私は（彼氏・彼女の姓）の名字を持つ{random}人の娘と{random}人の息子が欲しい！",
    sweet_el_7: "この世界には、君ほど美しい人は存在しない。",
  },
  romantic_el: {
    romantic_el_1: "(彼氏・彼女の名前)、もう恥ずかしい…やめて…",
    romantic_el_2:
      "ねえ、君はミルク、私はお茶だね。 ぜひ一緒にミルクティーを作ろうよ？ ほんとに楽しみ！",
    romantic_el_3:
      "君も息ができないの？　ねえ、一緒に呼吸をしよう　唇を合わせて",
    romantic_el_4: "もし君が花なら、美しい花だよ！2つの綺麗な口があってさ",
    romantic_el_5: "今すぐキスして! ",
    romantic_el_6: "何も言わないで、手を使って君の愛を証明して。",
    romantic_el_7: "一緒にポッキーを食べよう！ そうしないと、君を食べるよ。",
    romantic_el_8:
      "一緒にポッキーを食べよう！一緒にポッキーを食べないと、君のおいしさを味わえないよ。",
    romantic_el_9:
      "一緒にポッキーを食べよう！ そうしたら、私たちはぴったりフィットできる。",
    romantic_el_10:
      "一緒に手を繋いでポッキーを頬ずりしよう。君と一緒じゃなきゃ、この甘い味も半分に感じてしまうから",
  },
  horror_el: {
    horror_el_1:
      "ゾンビのパンデミックが起こって、ゾンビが君を引き裂いたら、私は医者になって君を治してあげたい。",
    horror_el_2:
      "もし君が殺されてしまった時、私の命で君を生き返らせることができるなら、私は喜んでそうするよ。",
    horror_el_3:
      "もしゾンビのパンデミックが発生して、君がゾンビに引き裂かれた場合、私は君の部分をすべて冷蔵庫に収めて永遠に保存するよ。",
    horror_el_4:
      "ゾンビのパンデミックが発生し、ゾンビがあなたを引き裂いたら、私は料理を学びます。",
    horror_el_5:
      "私は警察官であり、君は連続殺人犯なので、私は君を永遠にフォローするよ。",
    horror_el_6: "私が幽霊になったら、一万年君に憑いていくよ。",
    horror_el_7: "何も食べずに部屋に閉じ込められたら 君を味わうよ。",
    horror_el_8:
      "恥ずかしくて、でも胸が高鳴る、君が欲しいと思うと飢えてしまうような気持ちになる。何も食べずに閉じ込められたとしても、君と一緒ならそれだけで満足だ。",
    horror_el_9:
      "もしゾンビのパンデミックが発生して、君がゾンビに引き裂かれても、貴方は私の中で共に生きます。",
    horror_el_10:
      "もしゾンビのパンデミックが発生して、君がゾンビに引き裂かれた場合、君をゾンビには食べさせない。食べるのは私だけ",
  },
  dailywords_el: {
    dailywords_el_1:
      "世界は美しいけど、一人では広すぎて手に負えない。私のことを、王女のように背負って。一緒に見に行きましょう！",
    dailywords_el_2:
      "君には気づかれないように見ていたかもしれないけど、私は君の小さな仕草をこっそりと見ていたんだ。そんな君の素敵な一面に魅了されてしまっていたんだ。",
    dailywords_el_3:
      "僕は君のぬいぐるみが羨ましいよ。君と一緒にいられるなんて素敵だと思うんだ。",
  },
  question_el: {
    question_el_1: "クジラが君と私の親友の両方を食べたら、誰を助ける?",
    question_el_2: "橋から飛び降りろと言われたら、飛び降りる?",
    question_el_3: "荒々しいのが好き、それとも優しいのが好き?",
    question_el_4:
      "街を歩いていて、かわいい女の子・イケメンな男に突然ハグされたら、どうする?",
    question_el_5: "一緒に道を歩いていて、突然雨が降ったらどうする?",
    question_el_6:
      "もし私が君に触れられない病気になったとしても、君は私を愛してくれる?",
  },
  fantasy_el: {
    fantasy_el_1:
      "君が魔王になったとしても、私は変わらない。ずっと君のことを愛している。",
    fantasy_el_2:
      "もし悪魔によって君がさらわれたら、必ず君を救う勇敢なヒーロー英雄になるよ。",
    fantasy_el_3:
      "ヒーローになるのは簡単だけど、君を満足させるのは10倍難しいな。",
  },
};
export default jp;
